$nys-brand-primary: #09464c;
$nys-brand-bright27: #0e707a;
$nys-body-font-size: 14px;
$nys-body-font-family: ProximaNovaRegular,sans-serif;
$nys-header-font-family: DSari;
$nys-color-top-banner-fill: #cfd8dc;
$nys-color-error: #B71C1C; // Material Red 900
$nys-size-gutter: 24px;


@mixin icon_font($SIZE) {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: SIZE;
  display: inline-block;
  width: 1em;
 src : url("https://fonts.googleapis.com/icon?family=Material+Icons");
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  speak: none;
  text-decoration: none;
}
