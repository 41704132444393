/* You can add global styles to this file, and also import other style files */
@import './_vars.scss';
@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import '~@angular/cdk/overlay-prebuilt.css';
@import "~bootstrap-icons/font/bootstrap-icons.css";

body {
  font-family: $nys-body-font-family;
  font-size: $nys-body-font-size;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
}

// Calendar button
button.calendar,
button.calendar:active {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=") !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 23px;
  width: 2.75rem;
}

// Form Errors

input.form-control.ng-invalid.ng-touched,
input.form-control.ng-invalid.ng-dirty,
select.form-control.ng-invalid.ng-touched {
  border-color: $nys-color-error;
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($nys-color-error, 0.25);
  }
}

.form-errors {
  margin: 4px;
  .form-error {
    line-height: 13px;
    font-size: 13px;
    &:before {
      top: 2px;
      margin-right: 4px;
      position: relative;
      content: "error";
      @include icon_font(13px);
    }
    color: $nys-color-error;
  }
}

.form-note {
  margin-bottom: 16px;
}

// youtube container on login page
.intro-container {
  width: 560px;
  height: 315px;
}

@font-face {
  font-family: DSari;
  src: url(assets/DSari_Bold.otf);
}

/** Bootstrap new sizes? */
.btn.btn-hero {
  height: 60px;
  font-size: 24px;
  min-width: 180px;
  text-transform: uppercase;
}

/** Bootstrap overrides */
.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: $nys-brand-primary !important;
}

.btn-light,
.btn-light:hover,
.btn-light:active,
.btn-light:visited {
  background-color: #fff !important;
  color: #09464c !important;
}

.form-control.is-valid:focus,
.was-validated :valid.form-control {
  border-color: #dddddd !important;
  background-image: inherit !important;
  box-shadow: inherit !important;
}

.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #212529;
}


.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
}

.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}

#outdated {
  padding: 15px 15px 0px 15px;
  position: fixed;
  z-index: 9999;
  width: 100%;
  margin-top: 69px;
  #buttonUpdateBrowser {
    padding: 5px;
    text-decoration: underline !important;
  }
  .last {
    margin-top: -5.5em;
    position: absolute;
    right: 1em;
    a {
      font-size: 2em;
    }
  }
}
